export const homePage = {
	//    Home page swiper
	banner_content: [
		// {
		// 	img: "header_banner_4",
		// 	img_mobile:"banner_4",
		// 	image_tab: "banner_tab_4",
		// 	url: "",
		// 	title: "",
		// 	sub_title: "",
		// 	para: "",
		// 	button: "",
		// 	priority: "high",
		// },
		{
			img: "header_banner_3",
			img_mobile: "banner_mobile_3",
			image_tab: "banner_tab_3",
			url: "inventory-and-consumables-management",
			title: "Asset Management ",
			sub_title: "Lifecycle",
			para: "A Technology Platform that revolutionizes the asset journey, from onboarding, monitoring, management to disposal. Our solution covers IT, OT, IoT, and traditional assets, offering a unified view, data-driven insights, and automated workflows.",
			button: "",
			priority: "low",
		},
		{
			img: "header_banner_1",
			img_mobile: "banner_1",
			image_tab: "banner_tab_1",
			url: "facility-management-with-building-intelligence",
			title: "Facility Management with ",
			sub_title: "Building Intelligence",
			para: "Pioneering AI-enabled Smart Building Solutions backed by IoT technology, Building Analytics and Advanced Digitization for unparalleled Operational Efficiency, Sustainability and ROI",
			button: "",
			priority: "high",
		},
		{
			img: "header_banner_2",
			img_mobile: "banner_mobile_2",
			image_tab: "banner_tab_2",
			url: "edge-device+software+cloud=scalable-architecture",
			title: "Edge device + Cloud + Software = ",
			sub_title: "Scalable Architecture",
			para: "Sclera's scalable architecture features a proprietary edge device for local processing and a private/public cloud SaaS solution for centralized data management and advanced analytics, ensuring seamless scalability and real-time insights.",
			button: "",
			priority: "low",
		},
		
	],
	grid_boxes: {
		title: "Facility Management Reimagined - Don’t Manage, Master",
		desc: "Witness the Real-World Impact of Our Solutions in Action",
		// grid_box_items: [
		// 	{
		// 		header: "AI-enabled Onboarding",
		// 		para: "75% reduction in time to onboard and 80%",
		// 		img: "",
		// 	},

		// 	{
		// 		header: "Digital Inspections",
		// 		para: "Up to 50,000 work hours freed up for Inspections",
		// 		img: "",
		// 	},
		// 	{
		// 		header: "Asset Downtime",
		// 		para: "20% reduction in asset downtime and 10-20% decrease in operational expenses through realtime monitoring",
		// 		img: "",
		// 	},
		// 	{
		// 		header: "Maintenance and Repair",
		// 		para: "15 to 20% reduction in recurring maintenance and repair costs.",
		// 		img: "",
		// 	},
		// 	{
		// 		header: "Inventory and Consumables",
		// 		para: "Reduce stock-outs by up to 50% and overstocking by 30%",
		// 		img: "",
		// 	},
		// ],
	},

	video_building_content: [
		{
			title: "See it All, Manage It All: Facility Management made Easy",
			para: "Say goodbye to alarm overload and disconnected data. Sclera automates work orders, unifies building insights ,and streamlines workflows for a truly holistic view of your property.",
			video: "sclera_building_pop_up",
			mobilevideo: "building_pop_up_mobile",
		},
	],

	role: {
		title: "Work Smarter, Not Harder",
		para: "Find the right features for your role",
		role_options: [
			{
				selected: "Facility Manager",
				header: "Facility Manager",
				tabimg: "facility_manager_tab",

				img: "facility_manager",
				desc: "With data visualization dashboards and reports, gain real-time insights into key facility metrics (e.g., energy usage, space utilization, maintenance needs) to make informed decisions.",
			},

			{
				selected: "Janitorial Team",
				tabimg: "janitorial_team_tab",

				header: "Janitorial Team",
				img: "janitorial",
				desc: "Work order management system with mobile app access enables simplifying task assignment, track cleaning progress, and improve communication between team members.",
			},
			{
				selected: "Asset Engineers",
				tabimg: "asset_engineers_tab",

				header: "Asset Engineers",
				img: "asset_engineers",
				desc: "Centralized asset registry with maintenance scheduling capabilities help manage asset information, schedule preventive maintenance tasks, and leverage real-time sensor data to monitor asset health and performance.",
			},
			{
				selected: "Maintenance Technician",
				tabimg: "maintenance_technician_tab",

				header: "Maintenance Technician",
				img: "maintenance_technician",

				desc: "Mobile app for work order access, automated alerts, rounds, and audits improve response time by accessing work order details and requesting parts directly from the field. Submit reports efficiently through the mobile app.",
			},
			{
				selected: "Smart Buildings Manager",
				tabimg: "smart_buildings_tab",

				header: "Smart Buildings Manager",

				img: "smart_buildings_manager",
				desc: "Building automation system integration with data analytics help optimize energy consumption and environmental conditions based on real-time building data. Gain insights into overall smart building operations and identify areas for improvement.",
			},
		],
	},

	jll_card: {
		img: "",
	},

	facility_management: {
		header: "Beyond ",
		header1: "Facility Management,",
		header2: "  What sets us apart?",
		facility_management_cards: [
			{
				img: "field_first_approach",
				title: "Field-first approach",
				para: "A democratized solution addressing the challenges of frontline heroes and C-level execs alike.",
			},
			{
				img: "native_integrations",
				title: "Native Integrations",
				para: "Retrofits into clients’ existing ecosystem as a data enabler to maximize returns on other technology investments.",
			},
			{
				img: "proprietary_solution",
				title: "Proprietary Solution",
				para: "Sclera's patented platform centralizes all your building systems, acting as a unified command center for facilities.",
			},
			{
				img: "offline_mode",
				title: "Offline Mode",
				para: "Sclera's pioneering offline mode tackles connectivity challenges in remote areas.",
			},
			{
				img: "scalability",
				title: "Scalability",
				para: "We manage millions of square feet and assets. Sclera grows with you, effortlessly scaling to meet your needs.",
			},
		],
	},

	features_and_functionalities: {
		title: "Features and Functionalities",
		desc: "Take a deeper dive into the features and functionalities that enable tech-enabled, analytic-driven smart buildings and workforce. ",

		features_and_functionalities_selection: [
			{
				selection: "AI-Enabled Onboarding",
				img: "AI_enabled_Onboarding",
				mobimg: "AI_enabled_Onboarding",
				url: "",
				desc: "Utilizes OCR and AI technologies to rapidly onboard new assets by merely capturing pictures of the assets.",
				link: "/features/asset-digitization",
			},
			{
				selection: "Inventory and Consumables Management",
				img: "Inventory_and_consumables_mgt",
				mobimg: "Inventory_and_Consumables",

				url: "",
				desc: "Creation, tracking, and management of inventory, from perishables to critical assets.",
				link: "",
			},
			{
				selection: "Digital Inspections",
				link: "/features/inspection-digitization",
				img: "Digital_inspections",
				mobimg: "Digital_Inspections",

				url: "",
				desc: "Transition from manual pen-and-paper processes to digital checklists for efficient maintenance & compliance. ",
			},
			{
				selection: "BMS and IoT Monitoring",
				link: "/features/sensors",
				img: "BMS_and_IOT_Monitoring",
				mobimg: "BMS_and_IoT_Monitoring",

				url: "",
				desc: "Provides real-time insights such as energy usage, temperature, occupancy, equipment status, and more.",
			},
			{
				selection: "Data Platform Integration",
				link: "/features/integrated-os",
				img: "Data_Platform_Integration",
				mobimg: "Data_Platform_Integration",

				url: "",
				desc: "Facilitates the seamless and secure transmission of standardized data from Sclera to various cloud-based data platforms, providing a robust foundation for advanced analytics and insights. ",
			},
			{
				selection: "Asset Management",

				img: "Asset_Management",
				mobimg: "Asset_Management",

				url: "",
				desc: " A centralized platform for managing and monitoring all types of assets within a facility, including network devices, equipment, sensors, virtual servers, and more",
				link: "/features/asset-digitization",
			},

			{
				selection: "Remote Access",

				img: "Remote_access_and_network_monitoring",
				mobimg: "Remote_Access_and_Network",

				url: "",
				desc: "A comprehensive suite of tools for managing and monitoring all network devices within a facility. ",
				link: "/features/network-monitoring",
			},

			{
				selection: "Work Order Management",
				link: "/features/inspection-digitization",
				mobimg: "Work_order_Management",

				img: "WO_Management",
				url: "",
				desc: "Centralizes workflow from assignee to manager, comprehensive tracking of ongoing tasks and records.",
			},
			// {
			// 	selection: "Asset Tracking",

			// 	img: "AI_enabled_Onboarding",
			// 	url: "",
			// 	desc: "Provides accurate, real-time tracking of assets and navigation to locations/assets via mobile devices.",
			// },
		],
	},

	video_card: [
		{
			header: "INTEGRATE. DIGITIZE. ELEVATE",
			desc: "Sclera transforms your siloed building operations into one digital and centralized landscape",
			mobilevideo: "building_front_view_mobile",
			video: "building_frontview_video",
			card_energy: "Energy",
			card_room: "Air Quality",
			card_shield: "Security",
			card_access: "Access Control",
			card_hvc: "HVAC",
			card_bulb: "Lighting System",
		},
	],

	our_expertise: {
		title1: "Your Industry,",
		title2: " Our Expertise",
		desc: "Meet your unique industry needs and unlock new possibilities with us",

		expertise_swipper: [
			{
				header: "Commercial Workspaces",
				para: "Sclera's encompassing solution allows tech parks and workspaces to operate facilities effectively, maximize resource utilization, and improve overall user experience.",
				url: "/industries/commercial-workspaces",
				img: "commercial",
			},
			{
				header: "Hospitality",
				para: "Sclera offers an innovation solution to automates operations, improve the guest experience and increase operational efficiency in a rapidly changing hospitality sector.",
				url: "/industries/hospitality",
				img: "hospitality",
			},
			{
				header: "Restaurant Chains",
				para: "Sclera transforms the way restaurants and cafes operate, optimizing workflows and enhancing customer satisfaction. With features like order management, and menu engineering, sclera simplifies operations and boosts efficiency.",
				url: "/industries/restaurant-chains",
				img: "restaturant",
			},
			{
				header: "Retail Chains",
				para: "Sclera offers a complete solution in order to organize and improve performance through the transformation of shopping operations. Retailers now have the resources they need to compete with the competition in the fields of asset management and optimisation, consumer interaction, and store analytics thanks to Sclera.",
				url: "/industries/retail-chains",
				img: "retail",
			},
		],
	},

	customer_saying: {
		header: "What Our Customers Are Saying",
		para: "Discover how Sclera has transformed facility operations for businesses like yours",

		customer_fortune: [
			{
				id: 1,
				img: "building_card_saying",
				className: "customer_saying_second_card_main1",
				header: "Sclera's digital inspection technology helped us win an award for cost-saving in hazardous waste inspection",
				para: "- A Fortune 500 semiconductor company",
			},
			{
				id: 2,
				img: "customer_card",
				className: "customer_saying_second_card_main2",
				header: "Integrating with Sclera was a huge contributor in the award we bagged for energy efficiency.",
				para: "- A Fortune 100 consumer product",
			},
		],
	},

	blogs: {
		header: "Food for thought ",
		para: "Explore our blogs, videos, quiz and more…",

		blogs_swipper: [
			{
				img: "Blog94",
				title: "Facility Management",
				desc: "Is Changing to an FM System the Key to Corporate Success?",
				url: "is-changing-to-an-fm-system-the-key-to-corporate-success",
			},

			{
				img: "Blog93",
				title: "Facility Management",
				desc: "Fundamentals of Work Orders: Discussing Types & WO Lifecycle",
				url: "fundamentals-of-work-orders-types-lifecycle",
			},

			{
				img: "Blog92",
				title: "Facility Management",
				desc: "The Role of a Property Manager in the Real Estate Industry",
				url: "the-role-of-a-property-manager-in-the-real-estate-industry",
			},

			// {
			//     img: "Blog87",
			//     title: "Facility Management",
			//     desc: "Distinguishing Between Soft FM Services Vs. Hard FM Services",
			//     url: "soft-fm-services-vs-hard-fm-services",
			// },
		],
	},
};
